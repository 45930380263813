<ng-container [ngSwitch]="currentStep">
  <div class="d-flex flex-column justify-content-center" data-testid="proof-of-section-container">
    <div class="container col-12 col-sm-12 col-lg-8" [dmvFocus]="true" tabindex="-1" focusEventName="{{ focusEvent }}">
      <section id="mv-wrapper-next-steps" *ngSwitchCase="'survey'">
        <ng-content></ng-content>
        <div class="download-button-container" *ngIf="documentName && documentName.length > 0">
          <button
            class="download-button"
            [attr.aria-label]="'Download ' + documentName + ' PDF'"
            (click)="handleClickDownload()"
            mat-stroked-button
            color="primary"
          >
            Download {{ documentName }}
          </button>
        </div>
      </section>
      <section id="mv-wrapper-document-upload" *ngSwitchCase="'document_upload'">
        <h1 class="font-size-30">{{ documentName }}</h1>
        <ng-container *ngIf="uploadDocuments && uploadDocuments.length > 0">
          <dmv-display-error [displayErrors]="displayErrors"></dmv-display-error>
          <dmv-shared-feature-upload-documents
            *ngIf="uploadDocuments.length > 0"
            [existingUploadItems]="uploadDocuments"
            [enhancedId]="enhanced"
            [docaiPollDurationSeconds]="docaiPollDurationSeconds"
            [transactionId]="transactionId"
            [transactionType]="transactionType"
            (uploadsComplete)="uploadsComplete($event)"
            (uploadEvent)="uploadEventComplete($event)"
          >
          </dmv-shared-feature-upload-documents>
        </ng-container>
      </section>
      <section id="mv-wrapper-document-review" *ngSwitchCase="'review'">
        <h1 class="font-size-30">{{ documentName }}</h1>
        <ng-container *ngIf="reviewObjects">
          <dmv-documents-review
            [isReviewed]="reviewed"
            [hasReviewError]="displayErrors?.display"
            [reviewObjects]="reviewObjects"
            (reviewedUploads)="reviewedUploads($event)"
          ></dmv-documents-review>
        </ng-container>
      </section>
      <div class="footer-container">
        <dmv-footer></dmv-footer>
      </div>

      <section class="section-navigation">
        <dmv-bottom-navigation
          [showContinue]="stepConfiguration.showContinue"
          [showPrevious]="stepConfiguration.showPrevious"
          [previousText]="stepConfiguration.previousText"
          [showSubmit]="stepConfiguration.showSubmit"
          [submitText]="stepConfiguration.submitText"
          (submitClick)="submitSection()"
          (continueClick)="continueClick()"
          (previousClick)="previousClick()"
        ></dmv-bottom-navigation>
      </section>
    </div>
  </div>
</ng-container>
