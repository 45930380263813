<dmv-shared-feature-upload-panel
  #uploadPanels
  *ngFor="let item of uploadItems; index as i; trackBy: trackByFunction"
  [uploadItem]="item"
  [transactionId]="transactionId"
  [transactionType]="transactionType"
  [transactionData]="transactionData"
  [newUpload]="newUpload"
  [itemIndex]="i"
  [itemIndexBias]="indexBias"
  [docaiPollDurationSeconds]="docaiPollDurationSeconds"
  (uploadItemLoading)="onUploadItemLoadingChange($event)"
  (checkDocTypeChangeEligible)="onCheckDocTypeChangeEligible($event)"
  (docTypeChangeClicked)="onDocTypeChangeClicked($event)"
  (uploadItemChange)="uploadItemChanged($event, i)"
  (itemUploaded)="itemUploaded($event)"
  (uploadDetailReuploaded)="onUploadDetailReuploaded($event)"
>
</dmv-shared-feature-upload-panel>
