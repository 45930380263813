import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MultiUploadPanelsEvent, TransactionType, UploadDocumentDetail, UploadItem } from '@dmv/public/shared/http';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-shared-feature-upload-documents',
  styleUrls: ['./upload-documents.component.scss'],
  templateUrl: './upload-documents.component.html',
})
export class UploadDocumentsComponent {
  /**
   * The transaction object's id field
   */
  @Input() public transactionId: number | null = null;
  @Input() public transactionType: TransactionType;
  @Input() public transactionData: Map<string, string>;
  @Input() public docaiPollDurationSeconds: number;

  public get existingUploadItems(): UploadItem[] {
    return this._existingUploadItems;
  }

  @Input() public set existingUploadItems(existingUploadItems: UploadItem[]) {
    this._existingUploadItems = existingUploadItems;
  }

  @Input() public enhancedId: boolean;
  @Input() public documentList: number[];
  @Input() public displayHeader = true;
  @Input() public showNotes = true;
  @Output() public readonly checkDocTypeChangeEligible = new EventEmitter<UploadDocumentDetail>();

  @Output() public readonly uploadsComplete = new EventEmitter<boolean>();
  @Output() public readonly uploadEvent = new EventEmitter<MultiUploadPanelsEvent>();
  @Output() public readonly panelItemUploadEvent = new EventEmitter<UploadDocumentDetail>();
  @Output() public readonly docTypeChangeClicked = new EventEmitter<UploadItem>();
  @Output() public readonly uploadDetailReuploaded = new EventEmitter<UploadItem>();

  private _existingUploadItems: UploadItem[] = [];

  public updateDocumentUploadsValid($event): void {
    this.uploadsComplete.emit($event);
  }

  public uploadEventDone($event: MultiUploadPanelsEvent): void {
    this.uploadEvent.emit($event);
  }

  public panelItemUploaded($event): void {
    this.panelItemUploadEvent.emit($event);
  }

  public onCheckDocTypeChangeEligible($event: UploadDocumentDetail) {
    this.checkDocTypeChangeEligible.emit($event);
  }

  public onDocTypeChangeClicked($event: UploadItem) {
    this.docTypeChangeClicked.emit($event);
  }

  public onUploadDetailReuploaded($event: UploadDocumentDetail) {
    this.uploadDetailReuploaded.emit($event);
  }
}
